<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <div class="d-flex mb-3">
      <div class="d-flex flex-column align-center justify-center mr-2">
        <div class="circulo-checkin" />
        <div class="divider-checkin">
          <v-divider vertical inset />
        </div>
        <div class="circulo-checkin vazio-checkin" />
      </div>
      <div class="informacao-checkin flex-grow-1">
        <div>
          <span class="primary-grey-500--text">{{
            $t('modulos.roteiro_execucao_envio_material.checkin.cliente')
          }}</span>
          <span>{{ clienteAtualDeTotalClientes }} - {{ clienteNome }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t('modulos.roteiro_execucao_envio_material.checkin.endereco')
          }}</span>
          <a :href="`${googleMaps}/${endereco}`" target="_blank"
            class="text-decoration-underline primary-blue-500--text">{{ endereco }}</a>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t('modulos.roteiro_execucao_envio_material.checkin.qtdEntregas')
          }}</span>
          <span>{{ quantidadeEnviosMateriaisEntrega }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.checkin.qtdRecebimentos'
            )
          }}</span>
          <span>{{ quantidadeEnviosMateriaisRecebimento }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-end">
      
        <botao-padrao class="mb-2 mr-3 flex flex-grow-1" @click="checkinCliente" btn100>
          <v-icon class="mr-2">
            $playCircleOutline
          </v-icon>
          {{ $t('modulos.roteiro_execucao_envio_material.botoes.checkin') }}
        </botao-padrao>

        <botao-padrao outlined color="secondary" class="mb-2 flex flex-grow-1" @click="abrirVisualizar" btn100>
          <v-icon class="mr-2">
            $eyeOutline
          </v-icon>
          {{
            $t('modulos.roteiro_execucao_envio_material.botoes.visualizarRoteiro')
          }}
        </botao-padrao>
      </div>
  </div>
</template>
<script>
import { findIndex, cloneDeep } from 'lodash';
import { RoteiroEnvioMaterialService } from '@/common/services/cadastros';

export default {
  data() {
    return {
      roteiro: {},
      googleMaps: 'https://www.google.com.br/maps/place',
      indiceClienteDaVez: 0,
      clienteDaVez: {},
      endereco: '',
      clienteNome: '',
      quantidadeEnviosMateriaisEntrega: 0,
      quantidadeEnviosMateriaisRecebimento: 0,
      totalClientes: 0,
    };
  },
  computed: {
    tituloFormulario() {
      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.checkin',
        { data: new Date().toLocaleDateString() }
      );
    },
    clienteAtualDeTotalClientes() {
      return `${this.indiceClienteDaVez + 1}/${this.totalClientes}`;
    },
  },

  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.roteiro_execucao_envio_material.titulos.checkin')
    );
    this.$store.dispatch('Layout/iniciarCarregamento');
    await RoteiroEnvioMaterialService.buscarRoteiroDia()
      .then((res) => {
        this.roteiro = res.data;

        this.$store.dispatch('Roteiro/atualizarRoteiro', this.roteiro);

        const temClienteEmExecucaoIndex = findIndex(this.roteiro.clientes, {
          situacao: 'EmExecucao',
        });

        if (temClienteEmExecucaoIndex != -1) {
          this.clienteDaVez = this.roteiro.clientes[temClienteEmExecucaoIndex];
          this.$store
            .dispatch('Roteiro/atualizarClienteDaVez', this.clienteDaVez)
            .then(() => {
              this.$store.dispatch(
                'Roteiro/atualizarClienteDaVezIndex',
                temClienteEmExecucaoIndex
              );
              this.$router.push({
                name: 'roteiro-executar-envio-material-entrega-recebimento',
              });
            });
          return;
        }

        this.indiceClienteDaVez = findIndex(this.roteiro.clientes, {
          situacao: 'Programado',
        });

        if (this.indiceClienteDaVez === -1) {
          this.$router.push({ name: 'roteiro-executar-envio-material' });
          return;
        }

        this.clienteDaVez = this.roteiro.clientes[this.indiceClienteDaVez];

        this.$store.dispatch(
          'Roteiro/atualizarClienteDaVezIndex',
          this.indiceClienteDaVez
        );

        this.$store.dispatch(
          'Roteiro/atualizarClienteDaVez',
          this.clienteDaVez
        );

        const {
          nome,
          quantidadeEnviosMateriaisEntrega,
          quantidadeEnviosMateriaisRecebimento,
          endereco: {
            cep,
            logradouro,
            numero,
            bairro,
            unidadeFederativa: { nome: uf },
            municipio: { nome: cidade },
          },
        } = this.clienteDaVez;

        this.endereco = `${logradouro}, ${numero}, ${bairro}, ${cidade} - ${uf}, ${cep}`;
        this.clienteNome = nome;
        this.quantidadeEnviosMateriaisEntrega =
          quantidadeEnviosMateriaisEntrega;
        this.quantidadeEnviosMateriaisRecebimento =
          quantidadeEnviosMateriaisRecebimento;
        this.totalClientes = this.roteiro.totalClientes;
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
  },
  methods: {
    checkinCliente: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.checkInCliente(
        this.roteiro.roteiroId,
        this.clienteDaVez.id
      )
        .then(() => {
          const clienteDaVezClonado = cloneDeep(this.clienteDaVez);
          clienteDaVezClonado.situacao = 'EmExecucao';
          clienteDaVezClonado.enviosMateriais =
            clienteDaVezClonado.enviosMateriais.map((envio) => {
              return {
                ...envio,
                situacao: 'EmExecucao',
              };
            });
          this.$store
            .dispatch('Roteiro/atualizarClienteDaVez', clienteDaVezClonado)
            .then(() => {
              this.$router.push({
                name: 'roteiro-executar-envio-material-entrega-recebimento',
              });
            });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirVisualizar: function () {
      this.$router.push({
        name: 'roteiro-executar-envio-material-visualizar',
      });
    }

  },
};
</script>
<style lang="scss">
.circulo-checkin {
  background: #475467;
  width: 0.875rem;
  height: 0.875rem;
  border: 1.5px solid #475467;
  border-radius: 100%;
}

.vazio-checkin {
  background: none;
  border: 1.5px solid #e4e7ec;
}

.divider-checkin {
  height: 15.8rem;
}

.informacao-checkin {
  display: flex;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
